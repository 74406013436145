<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header title="Clientes" :dense="true" :breadcrumbs="breadcrumbs" :hiddenTab="true" :scroll="scroll">
      <template v-slot:main>
        <v-query-builder :query-map="filters" model="customer" :active.sync="activeFilter" />
        <v-btn class="ml-2" color="primary" @click="dialogCustomer=true"><v-icon left>mdi-domain</v-icon>Agregar cliente</v-btn>
      </template>
    </mini-header>
    <!-- end header -->
    <v-col cols="12" class="mt-20 pa-0">
      <v-col cols="12" class="px-0">
        <customer-list :show-create="true" @update:create="dialogCustomer = true" @activeBtnFilter="activeFilter = true"  />
      </v-col>
    </v-col>

    <!-- dialog new customer -->
    <v-dialog v-model="dialogCustomer" width="700" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Agregar cliente</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn class="ml-3" @click="dialogCustomer=false, clearInput()" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-0 overflow-y-auto" style="max-height: 710px;" min-height="700" scrollable>
          <v-row class="px-5" no-gutters>
            <v-col cols="3" class="pa-0">
              <span class="fontBody--text body-1">{{$t('generals.RUT')}}</span>
              <v-text-field v-model.trim="$v.customer.taxpayer.$model" :error="$v.customer.taxpayer.$error" v-dni="customer.country" required outlined single-line dense hide-details />
            </v-col>
            <v-col class="pa-0 ml-3">
              <span class="fontBody--text body-1">Nombre de la organización</span>
              <v-text-field v-model.trim="$v.customer.name.$model" :error="$v.customer.name.$error" outlined required single-line dense maxlength="100" hide-details />
            </v-col>
          </v-row>
          <v-row class="px-5 py-3" no-gutters>
            <v-col cols="5" class="pa-0">
              <span class="fontBody--text body-1">Actividad económica</span>
              <v-text-field v-model.trim="$v.customer.business_activity.$model" :error="$v.customer.business_activity.$error" outlined required single-line dense maxlength="64" hide-details />
            </v-col>
            <v-col class="pa-0 ml-3">
              <span class="fontBody--text body-1">Correo de emisión</span>
              <v-text-field v-model.trim="$v.customer.email.$model" :error="$v.customer.email.$error" outlined required single-line dense maxlength="64" hide-details />
            </v-col>
          </v-row>
          <v-row class="px-5 pb-0" no-gutters>
            <v-col cols="8" class="pa-0">
              <span class="fontBody--text body-1">Dirección</span>
              <v-text-field v-model.trim="$v.customer.address.$model" :error="$v.customer.address.$error" outlined required single-line dense maxlength="64" hide-details />
            </v-col>
            <v-col class="pa-0 ml-3">
              <span class="fontBody--text body-1">{{$t('generals.Comuna')}}</span>
              <v-select v-model="$v.customer.state.$model" :error="$v.customer.state.$error" :label="$t('generals.Selecciona una comuna')" :items="$store.getters['base/currentStates']" item-value="name" item-text="name" single-line hide-details dense height="30" outlined :no-data-text="`No existen ${$t('generals.Comuna')} para el país.`">
                <template v-slot:item="data">
                  <v-list-item-title class="font-weight-regular">{{data.item.name}}</v-list-item-title>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-divider class="my-4" />

          <div class="px-5 pt-1">
            <v-toolbar-title class="px-0 mb-3 subtitle-2 fontBody--text font-weight-medium">
              Crear actividad económica adicional
              <v-chip class="ml-2 px-1" x-small color="off">Opcional</v-chip>
            </v-toolbar-title>

            <div v-for="(d, i) in additionalTaxSettings" :key="i">
              <v-row class="py-1" align="center" no-gutters>
                <v-col cols="8">
                  <span class="fontBody--text body-1">Actividad económica</span>
                  <v-text-field v-model="d.description" outlined required single-line dense maxlength="64" hide-details />
                </v-col>
                <v-col>
                  <v-btn class="mt-4" v-if="i >= 1 || additionalTaxSettings.length > 1" @click="additionalTaxSettings.splice(i, 1)" text color="secondary" :ripple="false"><v-icon color="primary">mdi-delete</v-icon></v-btn>
                </v-col>
              </v-row>
            </div>
            <v-btn v-if="additionalTaxSettings.length < 10" @click.prevent="addRowAdditionalTaxSettings" text color="primary" :ripple="false"><v-icon x-small>mdi-plus</v-icon>
              <span class="font-weight-medium body-1">Crear otra actividad económica</span>
            </v-btn>
          </div>
          <v-divider class="my-4" />

          <div class="px-5 pt-1">
            <v-toolbar-title class="px-0 mb-3 subtitle-2 fontBody--text font-weight-medium">
              Crear dirección adicional
              <v-chip class="ml-2 px-1" x-small color="off">Opcional</v-chip>
            </v-toolbar-title>

            <div v-for="(d, i) in additionalAddress" :key="i">
              <div class="py-1 d-flex justify-space-between">
                <div class="col-7 pa-0">
                  <span class="fontBody--text body-1">Dirección</span>
                  <v-text-field v-model="$v.additionalAddress.$each[i].address.$model" :error="$v.additionalAddress.$each[i].address.$error" outlined required single-line dense maxlength="64" hide-details />
                </div>
                <div class="col-4 py-0 px-1" style="max-width: 360px;">
                  <span class="fontBody--text body-1">{{$t('generals.Comuna')}}</span>
                  <v-select v-model="d.state" :label="$t('generals.Selecciona una comuna')" :items="$store.getters['base/currentStates']" item-value="name" item-text="name" single-line hide-details dense height="30" outlined :no-data-text="`No existen ${$t('generals.Comuna')} para el país.`">
                    <template v-slot:item="data">
                      <v-list-item-title class="font-weight-regular">{{data.item.name}}</v-list-item-title>
                    </template>
                  </v-select>
                </div>
                <div class="col-1 pa-0" style="max-width: 30px;">
                  <v-btn class="mt-4 ml-n2" v-if="i >= 1 || additionalAddress.length > 1" @click="additionalAddress.splice(i, 1)" text color="secondary" :ripple="false"><v-icon color="primary">mdi-delete</v-icon></v-btn>
                </div>
              </div>
            </div>
            <v-btn v-if="additionalAddress.length < 10" @click.prevent="addRowAdditionalAddress" text color="primary" :ripple="false"><v-icon x-small>mdi-plus</v-icon>
              <span class="font-weight-medium body-1">Agregar otra dirección</span>
            </v-btn>
          </div>
          <v-divider class="my-4" />

          <div class="px-5 pt-1">
            <v-toolbar-title class="px-0 mb-3 subtitle-2 fontBody--text font-weight-medium">
              Agregar contactos
              <v-chip class="ml-2 px-1" x-small color="off">Opcional</v-chip>
            </v-toolbar-title>

            <div v-for="(d, i) in contacts" :key="i">
              <div class="py-1 d-flex justify-space-between">
                <div class="col-4 pa-0">
                  <span class="fontBody--text body-1">Nombre</span>
                  <v-text-field v-model.trim="d.name" outlined required single-line dense maxlength="64" hide-details />
                </div>
                <div class="col-4 py-0 px-1">
                  <span class="fontBody--text body-1">Email</span>
                  <v-text-field v-model="$v.contacts.$each[i].email.$model" :error="$v.contacts.$each[i].email.$error" outlined required single-line dense maxlength="64" hide-details />
                </div>
                <div class="col-3 pa-0" style="max-width: 290px;">
                  <span class="fontBody--text body-1">Teléfono</span>
                  <v-text-field v-model.trim="d.phone" v-mask="`${d.phone} # #### ####`" maxlength="16" outlined required single-line dense hide-details />
                </div>
                <div class="col-1 pa-0" style="max-width: 30px;">
                  <v-btn class="mt-5 ml-n2" v-if="i >= 1 || contacts.length > 1" @click="contacts.splice(i, 1)" text color="secondary" :ripple="false"><v-icon color="primary">mdi-delete</v-icon></v-btn>
                </div>
              </div>
            </div>
            <v-btn v-if="contacts.length < 10" @click.prevent="addRowContacts" text color="primary" :ripple="false"><v-icon x-small>mdi-plus</v-icon>
              <span class="font-weight-medium body-1">Agregar otro</span>
            </v-btn>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer/>
          <v-btn outlined @click="dialogCustomer=false, clearInput()">Cancelar</v-btn>
          <v-btn color="primary" :loading="$store.state.customers.createLoader" @click="postCustomer">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog new customer -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import { required, maxLength, email, url, requiredIf } from 'vuelidate/lib/validators'
import { rutValidator } from 'vue-dni'
import MiniHeader from '@/components/commons/MiniHeader'
import CustomerList from '@/modules/customer/components/CustomerList'
import VQueryBuilder from '@/components/VQueryBuilder/VQueryBuilder'
import ListViewMixin from '@/mixins/ListViewMixin'

export default {
  components: {
    CustomerList,
    VQueryBuilder,
    MiniHeader
  },
  mixins: [
    ListViewMixin
  ],
  data () {
    return {
      activeFilter: false,
      customerNotExists: false,
      scroll: 0,
      customer: {
        name: '',
        taxpayer: '',
        business_activity: '',
        country: null,
        currency: '',
        state: '',
        address: '',
        email: '',
        phone: '',
        website: ''
        // emails: [{ name: '', email: '', phone: '' }],
      },
      additionalTaxSettings: [{ description: '' }],
      additionalAddress: [{ address: '', state: '' }],
      contacts: [{ name: '', email: '', phone: '' }],
      dialogCustomer: false,
      filters: [
        {
          type: 'text',
          id: 'name',
          label: 'Nombre',
          value: null,
          category: 'a'
        },
        {
          type: 'text',
          id: 'taxpayer__tax_id',
          value: null,
          category: 'a',
          label: 'RUT'
        },
        {
          type: 'date',
          id: 'created',
          label: 'Fecha de creación',
          value: null,
          value2: null,
          category: 'b',
          operator: null,
          operators: ['es igual', 'es menor a', 'es mayor a', 'rango']
        },
        {
          type: 'text',
          id: 'email',
          label: 'Email',
          value: null,
          category: 'a',
          operators: ['contiene']
        }
      ],
      headers: [
        { text: 'Cliente', value: 'name', class: 'font-weight-bold', sortable: true },
        { text: 'Email', value: 'email', sortable: true },
        { text: 'Creado el', value: 'created', align: 'end', sortable: true }
      ],
      breadcrumbs: {
        main: 'Emisión',
        children: [
          {
            text: 'Clientes'
          }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      countriesList: state => state.base.countriesList
    }),
    country () {
      return this.customer.country ? this.countriesList.find(country => country.id === this.customer.country) : null
    }
  },
  created () {
    if ((typeof this.getList === 'function' && Object.keys(this.$route?.query).length)) {
      this.getList()
    }
  },
  watch: {
    'customer.country' () {
      if (this.customerNotExists) {
        this.customer.state = ''
        this.customer.taxpayer = ''
      }
    },
    'customer.taxpayer' (val) {
      if (val) this.debounceMethod(this.getTaxpayer)
    }
  },
  methods: {
    onScroll (e) {
      this.offsetTop = e.target?.scrollingElement?.scrollTop ?? 0
    },
    getTaxpayer () {
      this.$v.customer.taxpayer.$touch()

      if (this.$v.customer.taxpayer.$invalid) {
        return false
      }
      this.$store.dispatch('base/RETRIEVE', {
        resource: 'base/taxpayers',
        loader: false,
        id: this.customer.taxpayer.split('.').join('')
      })
      .then((response) => {
        this.customerNotExists = !!response.data.length
        const tp = response.data
        this.customer.name = tp.name
        this.customer.address = tp.address
        this.customer.phone = tp.phone
        this.customer.email = tp.email
        this.customer.state = tp.state
        this.customer.country = tp.country
        this.customer.business_activity = tp.irs_settings.cl_activity_description
      })
      .catch(() => {
        this.customerNotExists = true
      })
    },
    getList () {
      this.$store.dispatch('customers/LIST', {
        resource: 'customers',
        query: this.$route.query
      })
    },
    addRowAdditionalTaxSettings () {
      this.additionalTaxSettings.push({
        description: ''
      })
    },
    addRowAdditionalAddress () {
      this.additionalAddress.push({
        address: '',
        state: ''
      })
    },
    addRowContacts () {
      this.contacts.push({
        name: '',
        email: '',
        phone: ''
      })
    },
    postCustomer () {
      this.$v.customer.$touch()
      if (this.$v.customer.$invalid) return false

      if (this.additionalAddress[0].state.length) {
        this.$v.additionalAddress.$touch()
        if (this.$v.additionalAddress.$invalid) return false
      }
      if (this.contacts[0].phone.length || this.contacts[0].name.length) {
        this.$v.contacts.$touch()
        if (this.$v.contacts.$invalid) return false
      }

      this.$store.dispatch('customers/CREATE', {
        resource: 'customers',
        payload: {...this.customer, ...{ country: this.$store?.state?.auth?.account?.country ?? 'CL'}}
      })
      .then(async (response) => {
        await Promise.all(this.additionalTaxSettings.map(async (item) => {
          if (item.description.length) {
            this.$store.dispatch('customers/CREATE', {
              resource: 'customers/business_activities',
              payload: {...item, ...{ customer: response.data.id }}
            })
          }
        }))
        await Promise.all(this.additionalAddress.map(async (item) => {
          if (item.address.length || item.state.length) {
            this.$store.dispatch('customers/CREATE', {
              resource: 'customers/extra_addresses',
              payload: {...item, ...{ customer: response.data.id }}
            })
          }
        }))
        await Promise.all(this.contacts.map(async (item) => {
          if (item.email.length || item.name.length || item.phone.length) {
            this.$store.dispatch('customers/CREATE', {
              resource: 'customers/contacts',
              payload: {...item, ...{ customer: response.data.id }}
            })
          }
        }))
        this.dialogCustomer = false
        this.$router.push({ name: 'CustomerRetrieveUpdate', params: { id: response.data.id } })
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
    },
    clearInput () {
      this.$v.customer.$reset()
      this.customer = {
        name: '',
        taxpayer: '',
        business_activity: '',
        country: null,
        currency: '',
        state: '',
        address: '',
        email: '',
        phone: '',
        website: '',
        emails: [{ email: '' }],
        additionalTaxSettings: [{ business_activity: '' }],
        additionalAddress: [{ address: '', state: '' }],
        contacts: [{ name: '', email: '', phone: '' }]
      }
    }
  },
  validations: {
    customer: {
      address: {
        required,
        maxLength: maxLength(64)
      },
      email: {
        required,
        email,
        maxLength: maxLength(254)
      },
      name: {
        required,
        maxLength: maxLength(100)
      },
      phone: {
        maxLength: maxLength(16)
      },
      state: {
        required
      },
     taxpayer: {
        required,
        format: function (val) {
          if (this.customer.country === 'CL') {
            return val.length ? rutValidator(val) : true
          }
          return true
        },
        maxLength: maxLength(32)
      },
      business_activity: {
        required: requiredIf(function () {
          return this.customer.country === 'CL'
        }),
        maxLength: maxLength(64)
      },
      website: {
        url
      }
    },
    additionalAddress: {
      $each: {
        address: {
         required,
          maxLength: maxLength(64)
        }
      }
    },
    contacts: {
      $each: {
        email: {
         required,
          email,
          maxLength: maxLength(64)
        }
      }
    }
  }
}
</script>